import React from 'react';
import style from './News.module.css';
import useFetch from '../../hooks/useFetch';
import OpenNews from '../OpenNews/OpenNews'

function News() {

   const [openednews, setopenednews] = React.useState(false)
   const [opendata, setopendata] = React.useState([])

   // const host = "http://gudauribusiness.com"
   const host = "https://api.gudauribusiness.com"

    const { loading, error, data } = useFetch(`${host}${"/"}api/newses?populate=*`)
    if (loading) return <p></p>
    if (error) return console.log(error)
    
    function OpenBigNews(i) {
      setopenednews(true)
      window.scrollTo({ top: 0, behavior: 'smooth'})
      setopendata(i)
   }

  return (

  <div className={style.head} >
   {openednews === false ?  
   <div className={style.news}>
      <div className={style.news_cont}>
         {data.data.toReversed().map((i, index) => (
         <div className={style.news_item} key={index}>
            <img className={style.news_item_image} src={`${host}${i.attributes.CoverImage.data.attributes.url}`} />
            <div className={style.news_item_infocont}>
               <div className={style.news_item_title}>{i.attributes.Title}</div>
               <div className={style.news_item_desc}>{i.attributes.SmallDescription}</div>
               <div className={style.news_item_buttoncont}>
                  <div className={style.news_item_button} onClick={() => OpenBigNews(i)} >READ MORE</div>
                  <div className={style.news_item_date}>{i.attributes.publishedAt.substring(0, 10)}</div>
               </div>
            </div>
         </div>
         ))}
      </div>
   </div>
   : 
   <OpenNews props={opendata}/>
   }
  </div>    

  );
}

export default News;
