import { useEffect, useState } from 'react';

const useFetch = (url) => {
    const [data,setData]= useState(null)
    const [error,setError] = useState(null)
    const [loading,setLoading] = useState(true)

    useEffect(()=>{
        const fetchData = async ()=>{
            setLoading(true)
            try {
                const headers = { "Authorization": "Bearer 613f7ecd15a6524a1038172bb7280b7da6b2c38f8c2fc3f49ce687f74b692a01d59dfa1fbf56e56da54f21106f16e54e87ae53e5d00332fddc1392619f0a16b486e2418ed49ca4440e88ee434e39229e577dfdcdfbd5a6af765cf44b9acac03ca61d91a2248366967c6faae4b041d75ceb399c1af2607d6f8a71b408bb5d9edb"}
                const res = await fetch(url , { headers })
                const json = await res.json()
                setData(json)
                setLoading(false)
                
            } catch (error) {
                setError(error)
                setLoading(false)
                
            }
        }

        fetchData()
    
    },[url])

    return {loading, error, data}
}

export default useFetch