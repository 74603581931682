                                                                                                                                             
                                                                                                                                             
    //      ######                                                                                                                               
    //      ##########                                                                                                                             
    //      ###  #####  #                  ##########        ###########       ##############        ####   ####       #####   #######        ######      
    //      ### ####   #####             ##############    ###############     ################      ####   ####       #####   ########      #######      
    //          #####    #####         ######      ###    ######      #####    #####       #####     ####   ####       #####   ########     ########      
    //   ######    ######   ####      ######             #####         #####   #####         ####    ####   ####       #####   #########   #########      
    //   ########    ####### ###      #####              #####          ####   #####         #####   ####   ####       #####   ########## ##### ####      
    //   ###   ######    ### ###      #####              #####          ####   #####         #####   ####   ####       #####   ##### ########## ####      
    //   ### #   ######  ##  ###      ######             ######        #####   #####         ####    ####   ####       #####   #####  ########  ####      
    //    ######  #####   ######        ######    ####    #######   #######    #####      ######     ####    #####    #####    #####  #######   ####      
    //       ### ####    ####            ##############     #############      ################      ####    #############     #####   #####    ####      
    //       ### #  ###                     ########          #########        #############         ####       #########      #####            ####      
    //       ##########                                                                                                                            
    //        ######                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  

import React from 'react';
import style from './App.module.css';
import useFetch from './hooks/useFetch';
import News from './Pages/News/News';

function App() {

  const RefHome = React.useRef(null)
  const RefNews = React.useRef(null)

  const host = "https://api.gudauribusiness.com"
  // const host = "http://localhost:1337"

  const { loading, error, data } = useFetch(`${host}${"/"}api/advertises?populate=*`)
  if (loading) return <p></p>
  if (error) return console.log(error)

  return (

  <div className={style.app}>
    <div className={style.header} ref={RefHome}>
        <div className={style.header_container}>
          <a href="/" className={style.header_logo}></a>
          <div className={style.header_navbar}>
              <a className={style.header_navbar_item} href="/">Home</a>
              <a className={style.header_navbar_item} href="/">News</a>
              <a className={style.header_navbar_item} href="https://www.facebook.com/georgianmountainresortsbusinessassociation" target="_blank">Contact</a>
          </div>
        </div>
    </div>
    <div className={style.mainpage}>
        <div className={style.mainpage_left}>
          <div className={style.mainpage_left_header_top}>GUDAURI</div>
          <div className={style.mainpage_left_header_middle}>BUSINESS ASSOCIATION</div>
          <div className={style.mainpage_left_header_desc}>GBA - Gudauri Business Association is non governmental organization, which unions the local business for development of Gudauri resort in every direction.</div>
          <div className={style.mainpage_left_socials}>
              <div className={style.mainpage_left_mobile_socials}>
                <a className={style.mainpage_left_socials_facebookbtn} href="https://www.facebook.com/georgianmountainresortsbusinessassociation" target="_blank">
                    <div className={style.mainpage_left_socials_cont} >
                      <div className={style.mainpage_left_socials_facebookbtn_icon} ></div>
                      <div className={style.mainpage_left_socials_facebookbtn_texts}>
                          <div className={style.mainpage_left_socials_facebookbtn_head}>Visit Our</div>
                          <div className={style.mainpage_left_socials_facebookbtn_desc}>Facebook</div>
                      </div>
                    </div>
                </a>
                <a className={style.mainpage_left_socials_fb} href="https://www.facebook.com/georgianmountainresortsbusinessassociation" target="_blank"></a>
                <div className={style.mainpage_left_socials_instagram}></div>
              </div>
          </div>
        </div>
        <div className={style.mainpage_right}></div>
    </div>
    <div ref={RefNews}>
        <News/>
    </div>
    <img className={style.advertise_image} src={`${host}${data.data[0].attributes.coverimage.data.attributes.url}`} />
    <div className={style.footer}>
        <div className={style.footer_title}>Copyright © 2024 Gudauri Business Association</div>
        <a className={style.footer_codium} href="https://codium.ge/" target="_blank">Powered By <span style={{ color: "rgba(147, 105, 253, 1)" }}>CODIUM.GE</span></a>
    </div>
  </div>

  );
}

export default App;
