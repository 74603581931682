import React from 'react';
import style from './OpenNews.module.css';
import useFetch from '../../hooks/useFetch';


function OpenNews(props) {

  const host = "https://api.gudauribusiness.com"
  // const host = "http://localhost:1337"

  const [paragraphs, setparagraphs] = React.useState([])
  React.useEffect(() => {

   if(props.props.attributes.BigDescription !== null){
    ConvertDesc()
    console.log("a")
   }
   
  }, [props.props.attributes.BigDescription]);

  console.log()

  function ConvertDesc() {
  	const sentences = props.props.attributes.BigDescription.split('.').filter(sentence => sentence.trim() !== '');
  	const localparagraphs = []
  	for (let i = 0; i < sentences.length; i += 3) {
  		const paragraph = sentences.slice(i, i + 3).join('. ') + (i + 3 < sentences.length ? '.' : '');
  		localparagraphs.push(paragraph);
  		setparagraphs(localparagraphs);
  	}
  }

  return (

  <div className={style.main}>
    <div className={style.container}>
        <div className={style.imagecont}>
          <img className={style.coverimage} src={`${host}${props.props.attributes.CoverImage.data.attributes.url}`} />
          <a className={style.back} href='/'>GO BACK</a>
        </div>
        <div className={style.title}>{props.props.attributes.Title !== null ? props.props.attributes.Title : null }</div>
        <div className={style.desc}>
          {props.props.attributes.BigDescription !== null ? paragraphs.map((paragraph, index) => (
          <p key={index}>{paragraph.trim()}</p>
          )) : null}
        </div>
        <div className={style.date}>{"Date: "+props.props.attributes.publishedAt.substring(0, 10)}</div>
        {props.props.attributes.images.data !== null ? 
        <div>{props.props.attributes.images.data.map((i, index) => (<img key={index} className={style.image} src={`${host}${i.attributes.url}`} />))}</div>
        : null}
        {/* {props.props.attributes.images.data.map((i, index) => (<img key={index} className={style.image} src={`${host}${i.attributes.url}`} />))} */}
    </div>
  </div>

  );
}

export default OpenNews;
